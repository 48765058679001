import React, { useState, useEffect, useContext } from 'react';
import cx from 'classnames';
import clipboard from 'clipboard-polyfill';
import CodeBlockHighlighted from 'plaid-threads/CodeBlockHighlighted';
import { Language } from 'prism-react-renderer';

import {
  CodeBlockType,
  SelectOption,
  AnyLanguage,
  IOSCodeLanguage,
  AndroidCodeLanguage,
  CodeLanguage,
  FrontendCodeLanguage,
} from '../types';

import {
  LANGUAGE_OPTIONS,
  IOS_LANGUAGE_OPTIONS,
  ANDROID_LANGUAGE_OPTIONS,
  REACT_NATIVE_IOS_LANGUAGE_OPTIONS,
  FRONTEND_LANGUAGE_OPTIONS,
  SERVER_LANGUAGE_REVERSE_MAPPING,
  CLIENT_LANGUAGE_REVERSE_MAPPING,
} from '../constants';
import Context from '../../../contexts/docs';

import styles from './index.module.scss';
import useUser from 'src/contexts/docs/useUser';

interface Props {
  className?: Parameters<typeof cx>[0];
  type?: CodeBlockType;
  code: string;
  disableCopy?: boolean;
  title: string;
  disableSwitcher?: boolean;
  lang: Language | 'ruby' | 'java';
  showLineStart?: number;
  showLineEnd?: number;
}

const clientRegex = /<Client(?:QD?)?>(.*?)<\/Client(?:QD?)?>/g;
const secretRegex = /<Secret(?:QD?)?>(.*?)<\/Secret(?:QD?)?>/g;

const CodeBlock: React.FC<Props> = (props: Props) => {
  const {
    serverCodeLanguage,
    iOSLanguage,
    androidLanguage,
    reactNativeiOSLanguage,
    frontendCodeLanguage,
    dispatch,
    isLoggedIn,
    user,
  } = useContext(Context);
  const { patchPreferences } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const [isCopying, setIsCopying] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsCopying(false), 3000);
    return () => clearTimeout(timeout);
  }, [isCopying]);

  const imputeClientAndSecretKeys = (code: string) => {
    let newCode = code;
    if (user.showAPIKeys && isLoggedIn && user.team && user.sandboxSecret) {
      newCode = newCode.replace(clientRegex, (match) => {
        if (match.includes('ClientQD')) {
          return `"${user.team['_clientID']}"`;
        } else if (match.includes('ClientQ')) {
          return `'${user.team['_clientID']}'`;
        }
        return user.team['_clientID'];
      });
      newCode = newCode.replace(secretRegex, (match) => {
        if (match.includes('SecretQD')) {
          return `"${user.sandboxSecret}"`;
        } else if (match.includes('SecretQ')) {
          return `'${user.sandboxSecret}'`;
        }
        return user.sandboxSecret;
      });
    } else {
      // Hide <Client> </Client>, <ClientQ>, </ClientQD>, etc.
      newCode = newCode.replace(/<(?:\/)?Client(?:QD?)?>/g, '');
      newCode = newCode.replace(/<(?:\/)?Secret(?:QD?)?>/g, '');
    }
    return newCode;
  };

  const imputedCode = imputeClientAndSecretKeys(props.code);

  const onCopy = () => {
    clipboard.writeText(imputedCode);
    setIsCopying(true);
  };

  // based on the type (android, ios, or server) get the correct language select value
  // using the context / state (current user's preferred language)
  const getLanguageFromContextByType = (
    t: CodeBlockType,
  ): SelectOption<
    IOSCodeLanguage | AndroidCodeLanguage | CodeLanguage | FrontendCodeLanguage
  > => {
    switch (t) {
      case 'ios':
        return IOS_LANGUAGE_OPTIONS.find((o) => o.value === iOSLanguage);
      case 'android':
        return ANDROID_LANGUAGE_OPTIONS.find(
          (o) => o.value === androidLanguage,
        );
      case 'react_native_ios':
        return REACT_NATIVE_IOS_LANGUAGE_OPTIONS.find(
          (o) => o.value === reactNativeiOSLanguage,
        );
      case 'frontend':
        return FRONTEND_LANGUAGE_OPTIONS.find(
          (o) => o.value === frontendCodeLanguage,
        );
      case 'server':
      default:
        return LANGUAGE_OPTIONS.find((o) => o.value === serverCodeLanguage);
    }
  };

  // based on the type (android, ios, or server) get the correct
  // onChange dispatch for the language switcher
  const getOnChangeByType = (
    t: CodeBlockType,
  ): ((
    v: SelectOption<
      | IOSCodeLanguage
      | AndroidCodeLanguage
      | CodeLanguage
      | FrontendCodeLanguage
    >,
  ) => void) => {
    switch (t) {
      case 'ios':
        return (v: SelectOption<IOSCodeLanguage>) => {
          setIsOpen(false);
          dispatch({
            type: 'SET_IOS_LANGUAGE',
            payload: {
              iOSLanguage: v.value,
            },
          });
          patchPreferences({
            codingLanguageFrontend: CLIENT_LANGUAGE_REVERSE_MAPPING[v.value],
          });
        };
      case 'android':
        return (v: SelectOption<AndroidCodeLanguage>) => {
          setIsOpen(false);
          dispatch({
            type: 'SET_ANDROID_LANGUAGE',
            payload: {
              androidLanguage: v.value,
            },
          });
          patchPreferences({
            codingLanguageFrontend: CLIENT_LANGUAGE_REVERSE_MAPPING[v.value],
          });
        };
      case 'react_native_ios':
        return (_) => {
          setIsOpen(false);
          // no-op, there's only one language for react_native_ios
          // so not prematurely adding an unused action
        };
      case 'frontend':
        return (v: SelectOption<FrontendCodeLanguage>) => {
          setIsOpen(false);
          dispatch({
            type: 'SET_FRONTEND_LANGUAGE',
            payload: {
              frontendCodeLanguage: v.value,
            },
          });
          patchPreferences({
            codingLanguageFrontend: CLIENT_LANGUAGE_REVERSE_MAPPING[v.value],
          });
        };
      case 'server':
      default:
        return (v: SelectOption<CodeLanguage>) => {
          setIsOpen(false);
          dispatch({
            type: 'SET_CODE_LANGUAGE',
            payload: {
              serverCodeLanguage: v.value,
            },
          });
          patchPreferences({
            codingLanguageBackend: SERVER_LANGUAGE_REVERSE_MAPPING[v.value],
          });
        };
    }
  };

  // get the correct language switcher options based on type
  const getOptionsByType = (
    t: CodeBlockType,
  ): Array<
    SelectOption<
      | IOSCodeLanguage
      | AndroidCodeLanguage
      | CodeLanguage
      | FrontendCodeLanguage
    >
  > => {
    switch (t) {
      case 'ios':
        return IOS_LANGUAGE_OPTIONS;
      case 'android':
        return ANDROID_LANGUAGE_OPTIONS;
      case 'react_native_ios':
        return REACT_NATIVE_IOS_LANGUAGE_OPTIONS;
      case 'frontend':
        return FRONTEND_LANGUAGE_OPTIONS;
      case 'server':
      default:
        return LANGUAGE_OPTIONS;
    }
  };

  // TODO: Temp hack to remap unsupported react-prism-renderer languages to supported ones
  // https://github.com/FormidableLabs/prism-react-renderer/pull/64
  const languageRemap: Record<AnyLanguage, Language> = {
    // remapped languages
    java: 'typescript',
    ruby: 'coffeescript',
    node: 'javascript',
    html: 'jsx',
    swift: 'clike',
    kotlin: 'typescript',

    // default
    bash: 'bash',
    javascript: 'javascript',
    json: 'json',
    go: 'go',
    objectivec: 'objectivec',
    python: 'python',
    tsx: 'tsx',
  };

  // objectivec => { label: 'Objective C', value: 'objectivec' }
  const language = getLanguageFromContextByType(props.type);

  // get the appropriate dispatch for the onChange for this type of code block
  const onChange = getOnChangeByType(props.type);

  // get the appropriate select options for this type of code block
  const options = getOptionsByType(props.type);

  return (
    <CodeBlockHighlighted
      className={cx(props.className, styles.codeBlock)}
      isCopying={isCopying}
      {...props}
      code={imputedCode}
      lang={languageRemap[props.lang]}
      onClick={props.disableCopy ? null : onCopy}
      title={props.disableCopy ? null : props.title}
      dropdown={
        props.lang !== 'json' &&
        props.lang !== 'markup' &&
        props.lang !== 'jsx' &&
        !props.disableSwitcher
          ? {
              isOpen,
              onClick: () => setIsOpen(true),
              options,
              text: language.label,
              accessibilityLabel: 'Select Language',
              value: language,
              onChange,
              onEscape: () => {
                setIsOpen(false);
              },
            }
          : undefined
      }
      showLineStart={props.showLineStart}
      showLineEnd={props.showLineEnd}
    />
  );
};

CodeBlock.displayName = 'CodeBlock';

export default CodeBlock;
