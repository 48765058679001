import React, { createContext, ReactNode, useEffect, Dispatch } from 'react';
import createReducer from 'react-use/lib/createReducer';

// no actions yet
// import * as actions from './actionCreators';
import docsReducer, { DocsState, initialState } from './reducer';
import { DocsAction } from './actionTypes';
import middleware from './middleware';
import { SERVER_LANGUAGES } from '../../components/docs/constants';

export interface DocsContextShape extends DocsState {
  dispatch: Dispatch<DocsAction>;
}

const DocsContext = createContext<DocsContextShape>(
  initialState as DocsContextShape,
);

export const { Consumer, Provider } = DocsContext;

const useReducer = createReducer(middleware);

export const DocsProvider: React.FC<{
  children: ReactNode;
  value: Partial<DocsContextShape>;
}> = (props) => {
  const [state, dispatch] = useReducer(docsReducer, {
    ...initialState,
    // the value is set in index.tsx file in DocsBeta folder in order to override the toc being set to null upon route change
    // ( due to next.js upgrade to v.12.1.10 )
    ...props.value,
  });

  useEffect(() => {
    try {
      // TODO: figure out consistent namespace
      const localDocsState = localStorage.getItem('PLAID_DOCS_STATE');
      let stateFromLocalStorage = JSON.parse(localDocsState);

      stateFromLocalStorage = {
        ...initialState, // in case keys are unset or new
        ...stateFromLocalStorage,
      };

      dispatch({
        type: 'SET_INITIAL_STATE',
        payload: {
          androidLanguage: stateFromLocalStorage.androidLanguage,
          apiVersion: stateFromLocalStorage.apiVersion,
          checklists: stateFromLocalStorage.checklists,
          clientPlatform: stateFromLocalStorage.clientPlatform,
          iOSLanguage: stateFromLocalStorage.iOSLanguage,
          serverCodeLanguage:
            SERVER_LANGUAGES.find((v) => {
              return v === stateFromLocalStorage.serverCodeLanguage;
            }) || 'bash', // some people may have `curl` still stored in their localStorage
          reactNativeiOSLanguage: stateFromLocalStorage.reactNativeiOSLanguage,
          history: stateFromLocalStorage.history,
          dismissedBannerIds: stateFromLocalStorage.dismissedBannerIds,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch({
        type: 'SET_INITIAL_STATE',
        payload: initialState,
      });
    }
  }, []);
  return <Provider value={{ ...state, dispatch }}>{props.children}</Provider>;
};

export default DocsContext;
