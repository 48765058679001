import { DocsAction } from '../actionTypes';
import { DocsState } from '../reducer';

export type DocsLocalStorageState = Pick<
  DocsState,
  | 'androidLanguage'
  | 'apiVersion'
  | 'checklists'
  | 'clientPlatform'
  | 'iOSLanguage'
  | 'serverCodeLanguage'
  | 'dismissedBannerIds'
  | 'history'
>;

// List of actions on which to sync localStorage
export const LOCALSTORAGE_ALLOWLIST: Array<DocsAction['type']> = [
  'SET_API_VERSION',
  'SET_CODE_LANGUAGE',
  'SET_INITIAL_STATE',
  'SET_IOS_LANGUAGE',
  'SET_ANDROID_LANGUAGE',
  'SET_CLIENT_PLATFORM',
  'ADD_CHECKLIST',
  'REMOVE_CHECKLIST',
  'DISMISS_BANNER',
];

const localStorageMiddleware = (action: DocsAction, state: DocsState) => {
  if (!LOCALSTORAGE_ALLOWLIST.includes(action.type)) {
    return;
  }

  const sanitizedState: DocsLocalStorageState = {
    androidLanguage: state.androidLanguage,
    apiVersion: state.apiVersion,
    checklists: state.checklists,
    clientPlatform: state.clientPlatform,
    iOSLanguage: state.iOSLanguage,
    serverCodeLanguage: state.serverCodeLanguage,
    history: state.history,
    dismissedBannerIds: state.dismissedBannerIds,
  };
  localStorage.setItem('PLAID_DOCS_STATE', JSON.stringify(sanitizedState));
};

export default localStorageMiddleware;
