import { DocsAction } from '../actionTypes';
import { DocsState } from '../reducer';

import analyticsMiddleware from './analytics';
import localStorageMiddleware from './localStorage';

const middleware = (store: { getState: () => DocsState }) => (
  next: (action: DocsAction) => void,
) => (action: DocsAction) => {
  next(action);

  // Analytics and localStorage syncing are executed on nextState (i.e. the current state)
  const nextState = store.getState();
  analyticsMiddleware(action, nextState);
  localStorageMiddleware(action, nextState);
};

export default middleware;
