import { useContext } from 'react';
import DocsContext from '.';
import { DASHBOARD_API_URL } from 'src/components/docs/constants';
import { useTrack } from '../AnalyticsContext';
import { LOGGED_IN_DOCS } from 'src/lib/trackingEvents';
interface PatchBody {
  showAPIKeys?: boolean;
  codingLanguageBackend?: string;
  codingLanguageFrontend?: string;
}

interface UseUserHook {
  patchPreferences: (body: PatchBody) => void;
  getFlagValue: (flag: string) => boolean | null;
  isLoggedIn: boolean;
}

const useUser = (): UseUserHook => {
  const { user, dispatch, isLoggedIn } = useContext(DocsContext);
  const track = useTrack();
  const patchPreferences = (body: PatchBody) => {
    track({
      type: LOGGED_IN_DOCS.USER_CHANGED_SETTINGS,
      payload: {
        ...body,
      },
    });
    fetch(DASHBOARD_API_URL, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }).then((res) => {
      if (res.status !== 200) {
        throw new Error('Failed to update API key');
      }
      res.json().then((data) => {
        dispatch({ type: 'UPDATE_USER', payload: data });
      });
    });
  };

  const getFlagValue = (flag: string | undefined) => {
    return user.experiments[flag] ?? null;
  };

  return {
    patchPreferences,
    getFlagValue,
    isLoggedIn,
  };
};

export default useUser;
