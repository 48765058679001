import {
  getFrontendCodeFromString,
  getServerCodeFromString,
} from 'src/components/docs/utilities';
import {
  APIVersion,
  AndroidCodeLanguage,
  ArticleMetadata,
  ClientPlatform,
  CodeLanguage,
  ContentSelected,
  IOSCodeLanguage,
  Keys,
  TableOfContents,
  ReactNativeiOSCodeLanguage,
  FrontendCodeLanguage,
  SelectedCode,
  APIVersionPreview,
  BasicInfoTeam,
  ClientLanguageDashboard,
  Experiments,
} from '../../components/docs/types';
import { DocsAction } from './actionTypes';

export interface DocsState {
  androidLanguage: AndroidCodeLanguage;
  apiVersion: APIVersion | APIVersionPreview;
  checklists: { [k: string]: Array<string> };
  clientPlatform: ClientPlatform;
  iOSLanguage: IOSCodeLanguage;
  reactNativeiOSLanguage: ReactNativeiOSCodeLanguage;
  metadata: ArticleMetadata;
  serverCodeLanguage: CodeLanguage;
  showSearch: boolean;
  searchQuery: string;
  searchResultText: string;
  searchResultPath: string;
  searchResultPosition: number;
  tableOfContents: TableOfContents;
  tableOfContentsActiveItem: string;
  history: Array<string>;
  feedbackHelpful?: boolean;
  feedbackText?: string;
  feedbackSource?: string;
  openSearchClicked: boolean;
  frontendCodeLanguage: FrontendCodeLanguage;
  contentSelected: ContentSelected;
  dismissedBannerIds: Array<string>;
  selectedCode: SelectedCode;
  isLoggedIn: boolean | null;
  clientLanguage: ClientLanguageDashboard;
  user: {
    userId: string | null;
    showAPIKeys: boolean;
    team: BasicInfoTeam | null;
    sandboxPublicKey: string;
    sandboxSecret: string;
    email: string;
    experiments: Experiments;
  };
}

export const initialState: DocsState = {
  androidLanguage: 'kotlin',
  apiVersion: '2020-09-14',
  checklists: {},
  clientPlatform: 'web',
  iOSLanguage: 'swift',
  reactNativeiOSLanguage: 'objectivec',
  metadata: { description: '' },
  serverCodeLanguage: 'node',
  showSearch: false,
  searchQuery: null,
  searchResultText: null,
  searchResultPath: null,
  searchResultPosition: null,
  tableOfContents: null,
  tableOfContentsActiveItem: '',
  history: [],
  openSearchClicked: false,
  frontendCodeLanguage: 'tsx',
  contentSelected: {},
  dismissedBannerIds: [],
  selectedCode: { route: null, item: null, startLine: null, endLine: null },
  isLoggedIn: null,
  clientLanguage: 'JAVASCRIPT',
  user: {
    userId: '',
    email: '',
    showAPIKeys: true,
    team: null,
    sandboxPublicKey: '',
    sandboxSecret: '',
    experiments: {},
  },
};

export default function docsReducer(
  state: DocsState = initialState,
  action: DocsAction,
): DocsState {
  switch (action.type) {
    case 'SET_INITIAL_STATE':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_CODE_LANGUAGE':
      return {
        ...state,
        serverCodeLanguage: action.payload.serverCodeLanguage,
      };
    case 'SET_IOS_LANGUAGE':
      return {
        ...state,
        iOSLanguage: action.payload.iOSLanguage,
      };
    case 'SET_ANDROID_LANGUAGE':
      return {
        ...state,
        androidLanguage: action.payload.androidLanguage,
      };
    case 'SET_FRONTEND_LANGUAGE':
      return {
        ...state,
        frontendCodeLanguage: action.payload.frontendCodeLanguage,
      };
    case 'SET_API_VERSION':
      return {
        ...state,
        apiVersion: action.payload.apiVersion,
      };
    case 'SET_TOC_WAYPOINT':
      return {
        ...state,
        tableOfContentsActiveItem: action.payload.tableOfContentsActiveItem,
      };
    case 'REMOVE_CHECKLIST':
      const modChecklist = state.checklists;
      // only remove values for checklist keys that exist
      if (modChecklist[action.payload.key] != null) {
        modChecklist[action.payload.key] = modChecklist[
          action.payload.key
        ].filter((c) => {
          return c !== action.payload.value;
        });
      }
      return {
        ...state,
        checklists: modChecklist,
      };
    case 'ADD_CHECKLIST':
      const addChecklist = state.checklists;
      // create a new array for new checklist keys
      if (addChecklist[action.payload.key] == null) {
        addChecklist[action.payload.key] = [];
      }
      if (!addChecklist[action.payload.key].includes(action.payload.value)) {
        addChecklist[action.payload.key] = addChecklist[
          action.payload.key
        ].concat(action.payload.value);
      }
      return {
        ...state,
        checklists: addChecklist,
      };
    case 'SET_SEARCH_VISIBILITY':
      return {
        ...state,
        showSearch: action.payload.visibility,
        openSearchClicked: action.payload.openSearchClicked,
        searchQuery: null,
      };
    case 'DOCS_SEARCH_INPUT':
      return {
        ...state,
        searchQuery: action.payload.searchQuery,
      };
    case 'DOCS_SEARCH_RESULT':
      return {
        ...state,
        showSearch: false,
        searchResultText: action.payload.searchResultText,
        searchResultPath: action.payload.searchResultPath,
        searchResultPosition: action.payload.searchResultPosition,
      };
    case 'GLOBAL_KEY_PRESSED':
      let showSearch = state.showSearch;
      switch (action.payload.key) {
        case Keys.ESCAPE:
          showSearch = false;
          break;
        case Keys.FORWARD_SLASH:
          showSearch = true;

          break;
      }
      return {
        ...state,
        showSearch: showSearch,
      };
    case 'SET_CLIENT_PLATFORM':
      return {
        ...state,
        clientPlatform: action.payload.clientPlatform,
      };
    case 'DOCS_CSAT_FEEDBACK':
      return {
        ...state,
        feedbackHelpful: action.payload.helpful,
      };
    case 'DOCS_CSAT_FEEDBACK_ADDITIONAL':
      return {
        ...state,
        feedbackText: action.payload.text,
        feedbackSource: action.payload.source,
      };
    case 'RESET_FEEDBACK':
      return {
        ...state,
        feedbackHelpful: null,
        feedbackText: null,
        feedbackSource: null,
      };
    case 'SET_CONTENT_SELECTED':
      return {
        ...state,
        contentSelected: {
          ...state.contentSelected,
          ...action.payload,
        },
      };
    case 'DISMISS_BANNER':
      return {
        ...state,
        dismissedBannerIds: [...state.dismissedBannerIds, action.payload.id],
      };
    case 'SET_SELECTED_CODE':
      return {
        ...state,
        selectedCode: action.payload,
      };
    case 'LOGIN':
      return {
        ...state,
        isLoggedIn: true,
      };
    case 'UPDATE_USER':
      const frontendCodeLanguage = getFrontendCodeFromString(
        action.payload.codingLanguageFrontend,
      );
      return {
        ...state,
        serverCodeLanguage: getServerCodeFromString(
          action.payload.codingLanguageBackend,
        ),
        ...frontendCodeLanguage,
        user: {
          ...state.user,
          userId: action.payload['_id'],
          team: action.payload.team,
          showAPIKeys: action.payload.showAPIKeys,
          sandboxPublicKey: action.payload.sandboxPublicKey,
          sandboxSecret: action.payload.sandboxSecret,
          email: action.payload.email,
          experiments: action.payload.experiments,
        },
        clientLanguage: action.payload
          .codingLanguageFrontend as ClientLanguageDashboard,
      };
    case 'LOGOUT':
      return {
        ...state,
        isLoggedIn: false,
      };
    case 'TOGGLE_API_KEY':
      return {
        ...state,
        user: {
          ...state.user,
          showAPIKeys: !state.user.showAPIKeys,
        },
      };
    default:
      return state;
  }
}
