export type APIVersion =
  | '2020-09-14'
  | '2019-05-29'
  | '2018-05-22'
  | '2017-03-08';

export type APIVersionPreview = 'local-preview';

export type Partnerships =
  | 'Vesta'
  | 'Dwolla'
  | 'VoPay'
  | 'Modern Treasury'
  | 'Prime Trust'
  | 'Galileo'
  | 'Drive Wealth'
  | 'Interactive Brokers';

export type APIEndpointType =
  | 'items'
  | 'institutions'
  | 'accounts'
  | 'tokens'
  | 'processors'
  | 'products'
  | 'sandbox';

export type APIProductName =
  | 'assets'
  | 'auth'
  | 'balance'
  | 'identity'
  | 'income'
  | 'investments'
  | 'liabilities'
  | 'payment-initiation'
  | 'transactions'
  | 'transfer';

export type ErrorType =
  | 'item'
  | 'institution'
  | 'api'
  | 'assets'
  | 'payment'
  | 'bank-transfers'
  | 'deposit-switch'
  | 'sandbox'
  | 'invalid-request'
  | 'invalid-input'
  | 'invalid-result'
  | 'rate-limit-exceeded'
  | 'link'
  | 'recaptcha'
  | 'oauth';

export type CodeBlockType =
  | 'server'
  | 'ios'
  | 'android'
  | 'react_native_ios'
  | 'frontend';

export type ClientPlatform = 'web' | 'ios' | 'android';

export type IOSCodeLanguage = 'swift' | 'objectivec';

export type AndroidCodeLanguage = 'kotlin' | 'java';

export type ReactNativeiOSCodeLanguage = 'objectivec';

export type ServerCodeLanguage =
  | 'bash'
  | 'node'
  | 'java'
  | 'python'
  | 'ruby'
  | 'go';

export type FrontendCodeLanguage = 'javascript' | 'tsx';

export type ClientLanguage =
  | FrontendCodeLanguage
  | IOSCodeLanguage
  | AndroidCodeLanguage
  | ReactNativeiOSCodeLanguage;

export type ExampleLanguage = 'bash' | 'html' | 'javascript';

export type ResponseLanguage = 'json';

export type CodeLanguage =
  | ServerCodeLanguage
  | ExampleLanguage
  | FrontendCodeLanguage;

export type ServerLanguageDashboard =
  | 'CURL'
  | 'NODE'
  | 'PYTHON'
  | 'RUBY'
  | 'JAVA_BE'
  | 'GO'
  | 'UNKNOWN_BE';

export type ClientLanguageDashboard =
  | 'JAVASCRIPT'
  | 'REACT'
  | 'SWIFT'
  | 'OBJECTIVE_C'
  | 'KOTLIN'
  | 'JAVA_FE'
  | 'UNKNOWN_FE';

export type AnyLanguage =
  | ServerCodeLanguage
  | IOSCodeLanguage
  | AndroidCodeLanguage
  | ExampleLanguage
  | ResponseLanguage
  | FrontendCodeLanguage;

export interface LinkError {
  link_error_title?: string;
  link_error_heading: string;
  link_error_message: string;
  link_error_button: string;
  link_error_icon: string;
  error_code: string;
  mfa_type?: string;
}

export interface ServerError {
  error_type: string;
  error_code: string;
  error_message: string | null;
  display_message: string | null;
  suggested_action?: string | null;
  // assets-only
  causes?: Array<{
    display_message: string | null;
    error_code: string;
    error_message: string | null;
    error_type: string;
    suggested_action?: string | null;
    item_id: string;
  }>;
  http_code: number;
}

export interface ErrorReference {
  server: ServerError;
  link: LinkError | null;
}

export interface TOCBlock {
  id: string;
  level: number;
  title: string;
}

export interface Image {
  src: string;
  type: string;
}

export type TableOfContents = TOCBlock[];

export type ArticleMetadata = {
  description: string;
  section?: string;
  title?: string;
  noindex?: boolean;
  feedback?: boolean;
  toc?: boolean;
  minTocLevel?: number;
  maxTocLevel?: number;
  previousText?: string;
  previousUrl?: string;
  nextText?: string;
  nextUrl?: string;
  layout?: 'quickstart' | 'guide' | 'reference';
  relatedArticles?: Array<string>;
  nav?: boolean;
  secondLevelToc?: boolean;
  alwaysExpand?: boolean;
  parentTocLevel?: number;
  childTocLevel?: number;
  preloadImages?: Array<Image>;
  source?: string;
  flag?: keyof Experiments;
};

export enum Keys {
  ESCAPE = 'Escape',
  FORWARD_SLASH = '/',
}

export type SelectOption<T> = { label: string; value: T };

export type DocsAnalyticsBody = {
  androidLanguage: AndroidCodeLanguage;
  apiVersion: APIVersion | APIVersionPreview;
  clientPlatform: ClientPlatform;
  iOSLanguage: IOSCodeLanguage;
  serverCodeLanguage: CodeLanguage;
  showSearch: boolean;
  searchQuery: string;
  searchResultText: string;
  searchResultPath: string;
  searchResultPosition: number;
  tableOfContentsActiveItem: string;
  helpful?: boolean;
  text?: string;
  feedbackSource?: string;
  openSearchClicked: boolean;
};

export type SearchPagePriorities = {
  first: string[];
  last: string[];
};

export type ContentSelected = {
  [key: string]: string;
};

export type SelectedCode = {
  route: string | null;
  item: string | null;
  startLine: number | null;
  endLine: number | null;
};

export type BasicInfoTeam = {
  _id: string;
  _clientId: string;
  company: string;
  isPartner: boolean;
};

export type Key = {
  clientId: string;
  value: string;
  type: string;
  environment: string;
};

export type Experiments = {
  bankTransfersEnabled?: boolean;
  clientScopedWebhooks?: boolean;
  enableThreads?: boolean;
  helpDocRecommender?: boolean;
  paymentInDashboard?: boolean;
  enableWalletsInDashboard?: boolean;
  enableFundsSweeperConfig?: boolean;
  plaidIncidentEnabled?: boolean;
  showInternationalStatusDashboardGB?: boolean;
  showInternationalStatusDashboardUS?: boolean;
  bannerEnabled?: boolean;
  enableInstitutionLogs?: boolean;
  enableValidatorTool?: boolean;
  showInstitutionRegistrationV2?: boolean;
  enableFILogoUpload?: boolean;
  enableSecurityQuestionnaireDrafts?: boolean;
  enableDashboardOverviewCallout?: boolean;
  enableRestrictDAAByPRF?: boolean;
  enableSelfServeTroubleshoot?: boolean;
  enableSignalPortal?: boolean;
  enableSignalOnDemandMetrics?: boolean;
  enableBeneficialOwnersControlPerson?: boolean;
  enableTransferRefundsUI?: boolean;
  showEnrichTestingFlowInDashboard?: boolean;
  enableSignalInDashboard?: boolean;
  enableResellerPartnerEndCustomerV2Experience?: boolean;
  enableResellerPartnerInstantEnablement?: boolean;
  enableInternalDebugMode?: boolean;
  enablePermissionsManagerItemCreation?: boolean;
  enablePRFFormV2?: boolean;
  isPrioritySupportCustomer?: boolean;
  enableOverviewCallout?: boolean;
  enableButtonCustomization?: boolean;
  enableDashboardOverviewCustomBanner?: boolean;
  enableDPDPlatforms?: boolean;
  enableDashboardSearch?: boolean;
  enableBackendEvents?: boolean;
  enableDataPartnerMultiEnvironment?: boolean;
  enableSelfServeTerminations?: boolean;
  enableEmailVerification?: boolean;
  enableDTMv4DashboardUpdates?: boolean;
  enableTransferPlatformPage?: boolean;
  disableEnableNow?: string;
  enableTSPSettings?: boolean;
  enableFinancialInsights?: boolean;
  enableRevampedProductionRequestFlow?: boolean;
  enableTransferLedger?: boolean;
  enableKnownIssues?: boolean;
  enableKnownIssuesAdvancedOptions?: boolean;
  enableNonProdSupportFlow?: boolean;
  enableValidatorX?: boolean;
  showIdentityMatchSelfServe?: boolean;
};

export interface PartnerProps {
  partner: Partnerships;
  isPaymentProcessor: boolean;
  clientLibrary: string;
  subtitle?: string;
  registrationUrl: string;
  apiUrl: string;
  imagePartnerName?: string;
}
